import {
  fnum,
  formatters,
  removeWhitespace,
  validators,
} from 'folio-common-utils';
import { getCompanyNameLength } from '../../src/utils';
import { isValidCadasterAddress } from '../components/PersonInput';
import { type Page, pageMap } from '../paths';
import { type RootState, useStoreState } from '../state';
import { checkCompany } from '../state/companyCheck/selectors';
import type { Company, EditorReducerState, Person } from '../state/editor';

export function getIllegalCompanyNameCharacters(name: string) {
  const letters = name.match(
    /[^0-9A-ZÆØÅÀÁÂÈÉÊÌÍÎÒÓÔÙÚÛÝÄËÏÖÜŸ \-?.,&/+!§:;'()*]/gi,
  );
  return letters ? Array.from(new Set(letters)) : [];
}

export function startsWithCompanyTypeAbbreviation(name: string) {
  return /^(hf|nuf|rhf|sf|gfs|iks|ks|kf|fkf|ans|as|asa) /i.test(name);
}

// Validates some rules for a company name (e.g. minimum length and
// that it includes "AS"), but not whether it has illegal words.
export function companyNameIsSyntacticallyValid(name: string) {
  name = name.trim();

  // Must end with "AS" or "aksjeselskap"
  // https://lovdata.no/NL/lov/1985-06-21-79/§2-2
  if (!/ (as|aksjeselskap)$/i.test(name)) {
    return false;
  }

  if (getCompanyNameLength(name) < 3) {
    return false;
  }

  // Further rules can be checked on backend
  return true;
}

export interface ValidationError {
  message: string;
  page: Page;
  elementId: string;
}

export function validateFormOwner(person: Person) {
  return validateOwner(person);
}

export function validateOwner(person: Person) {
  return {
    name: validators.isValidName(person.name),
    pNum: isValidPnum(person.pNum),
    cadasterAddress: isValidCadasterAddress(person.cadasterAddress),
    email: validators.isValidEmail(person.email),
    phone: validators.isValidMobileNumber(person.phone),
  };
}

export function validateSignatory(person: Person) {
  return {
    name: validators.isValidName(person.name),
    pNum: isValidPnum(person.pNum),
    email: validators.isValidEmail(person.email),
    phone: validators.isValidMobileNumber(person.phone),
  };
}

export function validateCeo(person: Person) {
  return {
    name: validators.isValidName(person.name),
    pNum: isValidPnum(person.pNum),
    phone: validators.isValidMobileNumber(person.phone),
    email: validators.isValidEmail(person.email),
  };
}

export function validateBoardMember(person: Person) {
  return {
    name: validators.isValidName(person.name),
    pNum: isValidPnum(person.pNum),
    phone: validators.isValidMobileNumber(person.phone),
    email: validators.isValidEmail(person.email),
  };
}

export function validateChair(person: Person) {
  return {
    name: validators.isValidName(person.name),
    pNum: isValidPnum(person.pNum),
    phone: validators.isValidMobileNumber(person.phone),
    email: validators.isValidEmail(person.email),
  };
}

function validatePurpose(state: EditorReducerState): ValidationError[] {
  if (state.coreInfo.purpose) {
    return [];
  }
  return [
    {
      message: 'Du må beskrive hva selskapet skal drive med',
      page: 'step2',
      elementId: 'company-purpose',
    },
  ];
}

function validateName(state: RootState): ValidationError[] {
  const coreInfo = state.editor.coreInfo;
  const errors: ValidationError[] = [];
  const nameStatus = checkCompany(state, coreInfo.name);

  if (!companyNameIsSyntacticallyValid(coreInfo.name)) {
    errors.push({
      message:
        'Selskapet trenger et navn. Selskapsnavn må bestå av minst tre bokstaver fra det norske alfabetet, og slutte med «AS».',
      page: 'step2',
      elementId: 'companyName',
    });
  }

  if (nameStatus !== 'in_progress' && !nameStatus.properNameOk) {
    errors.push({
      message: 'Du kan ikke bruke navnet til et land, fylke eller en kommune',
      page: 'step2',
      elementId: 'companyName',
    });
  }

  if (nameStatus !== 'in_progress' && !nameStatus.existingNameOk) {
    errors.push({
      message: 'Navnet er i bruk',
      page: 'step2',
      elementId: 'companyName',
    });
  }

  return errors;
}

function validateCoreInfo(state: EditorReducerState): ValidationError[] {
  const coreInfo = state.coreInfo;
  const errors: ValidationError[] = [];

  if ((Number(coreInfo.capital) || 0) < 30000) {
    errors.push({
      message: `Aksjekapital må være minst ${formatters.formatAmount(30000, {
        currency: 'kroner',
      })}`,
      page: 'step3',
      elementId: 'capital',
    });
  }

  if (
    !state.formOwnerAsAddress &&
    !isValidCadasterAddress(coreInfo.cadasterAddress)
  ) {
    errors.push({
      message: 'Selskapets adresse mangler',
      page: 'step4',
      elementId: 'companyAddress',
    });
  }

  if (coreInfo.orgTransfer) {
    const { orgTransfer } = coreInfo;
    const companyIsInvalid =
      orgTransfer.orgId === '' || orgTransfer.orgName === '';

    if (companyIsInvalid) {
      errors.push({
        message: 'Oppgi selskapet som skal videreføres',
        page: 'step4',
        elementId: 'show-org-transfer-form',
      });
    }
  }

  return errors;
}

export function addressIsPostOfficeBox(address: string) {
  // Check word boundaries to avoid false positives like "boksbuvegen" and
  // "oppbekkvegen".
  return /\b(postboks|pb|boks)\b/i.test(address);
}

function validateShares(editor: EditorReducerState) {
  if (editor.ownershipType === 'single') {
    return [];
  }

  if (editor.ownershipType === 'company') {
    return editor.singleCompanyOwner
      ? []
      : [
          {
            elementId: '',
            message:
              'Selskapet skal eies av et selskap, men eierselskapet er ikke fylt ut',
            page: 'step3',
          } as ValidationError,
        ];
  }

  const errors: ValidationError[] = [];
  const assignedShares = Object.values(editor.equity).reduce(
    (prev, curr) => prev + Number(curr),
    0,
  );

  const shareCount = editor.coreInfo.shareCount;

  if (assignedShares !== Number(shareCount)) {
    errors.push({
      elementId: 'equity',
      message: `Selskapet har totalt ${formatters.formatNumber(
        shareCount,
      )} aksjer, men det er fordelt ${formatters.formatNumber(assignedShares)}`,
      page: 'step3',
    });
  }

  Object.entries(editor.equity).forEach(([id, equity]) => {
    if (equity === '' || Number(equity) === 0) {
      const owner: Person | Company = editor.people[id] || editor.companies[id];
      errors.push({
        elementId: '',
        message: `${owner.name} kan ikke få en eierandel for 0\xA0kr`,
        page: 'step3',
      });
    }
  });

  return errors;
}

export function validateFounding(state: RootState): ValidationError[] {
  const editorState = state.editor;
  const coreInfoErrors = validateCoreInfo(editorState);
  const nameErrors = validateName(state);
  const purposeErrors = validatePurpose(editorState);
  const shareErrors = validateShares(editorState);
  return [...coreInfoErrors, ...nameErrors, ...purposeErrors, ...shareErrors];
}

export type GroupedErrors = Map<Page, ValidationError[]>;

export function errorsByPage(errors: ValidationError[]): GroupedErrors {
  const groups: GroupedErrors = new Map();
  for (const page of new Set(errors.map(e => e.page))) {
    groups.set(
      page,
      errors.filter(e => e.page === page),
    );
  }
  return groups;
}

export function useValidationStatusByPage(): Set<string> {
  const validPages = new Set<string>();
  const state = useStoreState();
  const { people, pagesMarkedForValidation } = state.editor;

  const errors = validateFounding(state);
  const byPage = errorsByPage(errors);

  const formOwner = people[state.editor.formOwner];
  const page1Fields = validateFormOwner(formOwner);
  const step1Valid = Object.values(page1Fields).every(value => value);
  if (step1Valid) {
    validPages.add(pageMap.step1);
  }

  if (!byPage.has('step2')) {
    validPages.add(pageMap.step2);
  }

  if (!byPage.has('step3') && pagesMarkedForValidation.includes('step3')) {
    validPages.add(pageMap.step3);
  }

  if (!byPage.has('step4') && pagesMarkedForValidation.includes('step4')) {
    validPages.add(pageMap.step4);
  }

  return validPages;
}

export function isValidPnum(pNum: string) {
  if (removeWhitespace(pNum) === '0'.repeat(11)) {
    // for testing
    return true;
  }

  return validators.isValidPnum(pNum) && fnum.isAtLeast18(pNum);
}
